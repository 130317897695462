<!-- 
@Author ——ZGM
@Date ——2022年
-->
<template>
  <div class="newSource">
    <div class="banner"></div>
    <!-- smartPower -->
    <div class="smartPower">
      <div class="LinkContariner">
        <div class="right" :class="{ moveSuRight: isMove }">
          <img src="../../assets/images/newSource/power_1.jpg" alt="" />
        </div>

        <div class="left" :class="{ moveSuLeft: isMove }">
          <p>智慧电力解决方案</p>
          <p>Smart Power</p>
          <p>
            <span></span>
          </p>
          <p class="commonP_my">
            领氪智慧电力解决方案以电力物联网为基础，充分利用传感器技术、移动互联
          </p>
          <p class="commonP_my">
            网技术、边缘计算技术、AI机器人技术等，实现从发电、输变配电、售电到用
          </p>
          <p class="commonP_my">
            电的智能化提升和改造，从而助力电力企业的数字化转型和产业升级。
          </p>
        </div>
      </div>
<!-- 
      <div class="colover_1"></div>
      <div class="colover_2"></div> -->
    </div>

    <!--enIot-->
    <div class="enIot">
      <div class="iotContainer">
        <div class="iotTitle">
          <p :class="{ GIS_title: isGIS }">EnIoT能源物联网平台</p>
        </div>

        <!-- 描述 -->
        <div class="desContainer">
          <div class="describe" :class="{ des_title_GIS: isGIS }">
            <p>
              EnIoT能源物联网平台采用传感器技术、嵌入式技术、边缘计算技术、区块链等技术，把能源生产、存储、配送、消费等能源基础设施通过先进信息通信技术、网络技术连接
            </p>
            <p>
              起来，并运行特定的程序，实现智能感知、智能计算、智能处理、智能决策、智能控制的目标，用“物联网+智慧能源”为行业赋能，加速行业数字转型
            </p>
          </div>
        </div>
        <!-- 图片 -->
        <div class="photo">
          <img
            :class="{ photo_title_GIS: isGIS }"
            src="../../assets/images/newSource/power_3.png"
            alt=""
          />
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="colover_5"></div>
      <div class="colover_6"></div> -->
    </div>

    <!-- plat -->
    <div class="plat">
      <div class="iotContainer">
        <div class="iotTitle">
          <p :class="{ plat_title: isplat }">平台功能</p>
        </div>

        <!-- 图片 -->
        <div class="photo">
          <img
            :class="{ photo_title_plat: isplat }"
            src="../../assets/images/newSource/platFn.jpg"
            alt=""
          />
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="colover_5"></div>
      <div class="colover_6"></div> -->
    </div>

    <!-- 应用场景 -->
    <div class="applicationScenarios">
      <div class="my_container">
        <div class="moveTitleP">
          <div class="title" :class="{ my_change_center: isCenarios }">应用场景</div>
        </div>

        <!-- 图片 -->
        <div class="my_photo">
          <ul :class="{ photo_isCenarios: isCenarios }">
            <li>
              <div class="img">
                <img
                  class="my_img"
                  src="../../assets/images/newSource/EnIoT1.png"
                  alt=""
                />
              </div>
              <p class="title">分布式风力发电</p>
              <div class="text">
                为分布式风力发电企业提供风机设备管理、场站监控、发电分析及预测、异常报警等
              </div>
            </li>

            <li>
              <div class="img">
                <img
                  class="my_img"
                  src="../../assets/images/newSource/EnIoT2.png"
                  alt=""
                />
              </div>
              <p class="title">分布式光伏发电</p>
              <div class="text">
                为分布式光伏发电企业提供光伏设备管理、场站监控、发电分析及预测、异常报警等
              </div>
            </li>

            <li>
              <div class="img">
                <img
                  class="my_img"
                  src="../../assets/images/newSource/EnIoT3.png"
                  alt=""
                />
              </div>
              <p class="title">楼宇建筑能源管理</p>
              <div class="text">
                实现楼宇建筑能源的分户分项计量、能源能耗监测电能质量、环境舒适监控，多租户管理
              </div>
            </li>

            <li>
              <div class="img">
                <img
                  class="my_img"
                  src="../../assets/images/newSource/EnIoT4.png"
                  alt=""
                />
              </div>
              <p class="title">政府及公用事业</p>
              <div class="text">
                重点能耗单位监测，用能分析能效评估需求侧响应，智慧能源服务创新应用
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="colover_5"></div>
      <div class="colover_6"></div> -->
    </div>

    <!-- numberPower -->
    <div class="numberPower">
      <div class="containerNum">
        <div class="left" :class="{ ele_left: isEle }">
          <p>基于数字孪生的智能电厂</p>
          <p>Intelligent power plant based on digital twin</p>
          <p>
            基于数字孪生的智能电厂通过结合数字孪生与DCS，能实现电厂由实到虚的全息投影和全局可视，并通过DCS对生产流程的仿真建模和I/O交互功能，实现由虚到实的反向控制，从而实现虚实融合的电厂管理新模式。
          </p>
        </div>
        <div class="right" :class="{ ele_right: isEle }">
          <img src="../../assets/images/newSource/power_4.jpg" alt="" />
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="iotContainer">
        <div class="iotTitle">
          <p :class="{ bottom_title: isBottom }">主要功能</p>
        </div>

        <!-- 图片 -->
        <div class="photo">
          <img
            :class="{ photo_title_bottom: isBottom }"
            src="../../assets/images/newSource/power_5.jpg"
            alt=""
          />
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="colover_5"></div>
      <div class="colover_6"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMove: false,
      isGIS: false,
      isplat: false,
      isCenarios: false,
      isEle: false,
      isBottom: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    // 元素滚动监听
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      if (scrollTop <=1120 && !this.isMove) {
        this.isMove = true;
      }
      if (
        (scrollTop > 480 && scrollTop < 490 && !this.isLI) ||
        (scrollTop < 1440 && scrollTop > 490 && !this.isLI)
      ) {
        this.isLI = true;
      }

      if (scrollTop > 387 && scrollTop < 1820 && !this.isGIS) {
        this.isGIS = true;
      }

      if (scrollTop > 1100 && scrollTop < 2433 && !this.isplat) {
        this.isplat = true;
      }

      if (scrollTop > 1710 && scrollTop < 3110 && !this.isCenarios) {
        this.isCenarios = true;
      }

      if (scrollTop > 2330 && scrollTop < 3657 && !this.isEle) {
        this.isEle = true;
      }

      if (scrollTop > 3110 && scrollTop < 3970 && !this.isBottom) {
        this.isBottom = true;
      }

      //   if (
      //     (scrollTop > 1354 && scrollTop < 1370 && !this.isBottom_1) ||
      //     (scrollTop < 2133 && scrollTop > 1370 && !this.isBottom_1)
      //   ) {
      //     this.isBottom_1 = true;
      //   }
    },
  },
};
</script>

<style lang="less" scoped>
.newSource {
  .banner {
    height: 500px;
    background: url("../../assets/images/newSource/banner.jpg") no-repeat center;
    background-size: cover;
  }

  .smartPower {
    height: 624px;
    padding-top: 106px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    .LinkContariner {
      width: 1200px;
      height: 538px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      .left {
        margin-top: 50px;
        width: 600px;
        position: absolute;
        right: -800px;

        p:nth-child(1) {
          color: #1f87e8;
          font-family: "Microsoft YaHei";
          font-size: 32px;
          font-weight: 400;
          height: 42px;
          line-height: 42px;
          letter-spacing: 1px;
        }

        p:nth-child(2) {
          color: #1f87e8;
          font-family: "Microsoft YaHei";
          font-size: 46px;
          font-weight: 600;
          padding-left: 5px;
          letter-spacing: 1px;
          height: 60px;
          line-height: 60px;
        }
        p:nth-child(3) {
          width: 90px;
          height: 6px;
          background: #4b4b4b;
          margin: 36px 0 30px 0;

          span {
            display: block;
            width: 3px;
            height: 6px;
            background: #fff;
            margin: 0 auto;
          }
        }
        .commonP_my {
          color: #898989;
          font-family: "Microsoft YaHei";
          font-size: 14px;
          height: 26px;
          line-height: 26px;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }

      .right {
        width: 506px;
        height: 338px;
        position: absolute;
        left: -800px;
        img {
          width: 506px;
          height: 338px;
        }
      }
    }
    .colover_1 {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      height: 644px;
      width: 350px;
    }

    .colover_2 {
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      height: 644px;
      width: 350px;
    }
  }

  .enIot {
    height: 626px;
    background: #f6f6f6;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
    .iotContainer {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .iotTitle {
        width: 100%;
        height: 38px;
        text-align: center;
        font-family: "Microsoft YaHei";
        color: #1f87e8;
        font-size: 30px;
        margin-bottom: 54px;
        position: relative;
        overflow: hidden;

        p {
          position: absolute;
          right: -562px;
        }
      }

      .desContainer {
        height: 52px;
        margin-bottom: 62px;
        position: relative;
        overflow: hidden;

        .describe {
          color: #898989;
          font-size: 14px;
          font-family: "Microsoft YaHei";
          position: absolute;
          left: -1062px;

          p {
            line-height: 26px;
            text-align: center;
          }
        }
      }

      .photo {
        width: 806px;
        height: 280px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        img {
          width: 806px;
          height: 280px;
          position: absolute;
          right: -918px;
        }
      }
    }

    .colover_5 {
      position: absolute;
      left: 0;
      top: 0;
      background: #f6f6f6;
      height: 726px;
      width: 350px;
    }

    .colover_6 {
      position: absolute;
      right: 0;
      top: 0;
      background: #f6f6f6;
      height: 726px;
      width: 350px;
    }
  }

  .plat {
    height: 554px;
    background: #fff;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
    .iotContainer {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .iotTitle {
        width: 100%;
        height: 38px;
        text-align: center;
        font-family: "Microsoft YaHei";
        color: #4b4b4b;
        font-size: 30px;
        margin-bottom: 54px;
        position: relative;
        overflow: hidden;

        p {
          position: absolute;
          left: -562px;
        }
      }

      .photo {
        width: 970px;
        height: 340px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        img {
          width: 970px;
          height: 340px;
          position: absolute;
          right: -918px;
        }
      }
    }

    .colover_5 {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      height: 726px;
      width: 350px;
    }

    .colover_6 {
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      height: 726px;
      width: 350px;
    }
  }

  // 应用场景
  .applicationScenarios {
    height: 546px;
    background: #f6f6f6;
    padding-top: 70px;
    .my_container {
      width: 1200px;
      height: 100%;

      margin: 0 auto;

      .moveTitleP {
        width: 100%;
        height: 38px;
        text-align: center;
        font-family: "Microsoft YaHei";
        color: #1f87e8;
        font-size: 30px;
        margin-bottom: 54px;
        position: relative;
        overflow: hidden;
        .title {
          position: absolute;
          right: -560px;
        }
      }
    }

    .my_photo {
      width: 1000px;
      height: 306px;
      margin: 0 auto;
      position: relative;
      ul {
        list-style: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        background: #fff;
        position: absolute;
        left: -1116px;

        li {
          background: #fff;
          .img {
            width: 226px;
            height: 176px;
            margin-bottom: 14px;
            .my_img {
              width: 226px !important;
              height: 176px !important ;
              display: inline-block;
            }
          }

          .title {
            font-size: 22px;
            color: #000;
            font-weight: bolder;
            background: #fff;
          }

          .text {
            width: 240px;
            margin-top: 14px;
            line-height: 28px;
            color: #b8b8b8;
            font-size: 17px;
            font-weight: 700;
            font-family: "Microsoft YaHei";
            background: #fff;
          }
        }
      }
    }
  }

  .numberPower {
    height: 536px;

    .containerNum {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;

      .left {
        width: 428px;
        position: absolute;
        top: 120px;
        left: -428px;
        p:first-child {
          color: #1aa0f8;
          font-size: 32px;
          font-family: "Microsoft YaHei";
          font-weight: 700;
        }
        p:nth-child(2) {
          color: #4b4b4b;
          font-size: 14px;
          font-family: "Microsoft YaHei";
          margin-top: 5px;
          margin-bottom: 36px;
        }

        p:nth-child(3) {
          color: #4b4b4b;
          font-size: 14px;
          font-family: "Microsoft YaHei";
          line-height: 28px;
        }
      }

      .right {
        width: 632px;
        height: 352px;
        position: absolute;
        top: 120px;
        right: -632px;
        img {
          width: 632px;
          height: 352px;
        }
      }
    }
  }

  .bottom {
    height: 622px;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
    .iotContainer {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .iotTitle {
        width: 100%;
        height: 38px;
        text-align: center;
        font-family: "Microsoft YaHei";
        color: #4b4b4b;
        font-size: 30px;
        margin-bottom: 54px;
        position: relative;
        overflow: hidden;

        p {
          position: absolute;
          right: -562px;
        }
      }

      .photo {
        width: 968px;
        height: 468px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        img {
          width: 968px;
          height: 468px;
          position: absolute;
          left: -918px;
        }
      }
    }

    .colover_5 {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      height: 726px;
      width: 450px;
    }

    .colover_6 {
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      height: 726px;
      width: 450px;
    }
  }
}
</style>
<style lang="less" scoped>
.moveSuLeft {
  animation: suLeft_1 1.5s ease forwards;
}

.moveSuRight {
  animation: suRight_1 1.5s ease forwards;
}

.GIS_title {
  animation: GIS_1 1s ease forwards;
}

.des_title_GIS {
  animation: GIS_2 1s ease forwards;
}
.photo_title_GIS {
  animation: GIS_3 1s ease forwards;
}

.plat_title {
  animation: plat_1 1s ease forwards;
}

.photo_title_plat {
  animation: plat_3 1s ease forwards;
}

.my_change_center {
  animation: moveRightToLeft 1s ease forwards;
}

.photo_isCenarios {
  animation: isCenarios_2 1s ease forwards;
}

.ele_left {
  animation: ele_1 1s ease forwards;
}

.ele_right {
  animation: ele_2 1s ease forwards;
}

.bottom_title {
  animation: bottom_1 1s ease forwards;
}

.photo_title_bottom {
  animation: bottom_2 1s ease forwards;
}

@keyframes suLeft_1 {
  from {
    right: -800px;
  }
  to {
    right: 0;
  }
}

@keyframes suRight_1 {
  from {
    left: -800px;
  }
  to {
    left: 0;
  }
}

@keyframes GIS_1 {
  from {
    right: -562px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes GIS_2 {
  from {
    right: -1062px;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes GIS_3 {
  from {
    right: -918px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes plat_1 {
  from {
    left: -562px;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes plat_3 {
  from {
    right: -918px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes moveRightToLeft {
  from {
    right: -560px;
  }
  to {
    right: 0px;
    width: 100%;
  }
}

@keyframes isCenarios_2 {
  from {
    left: -1116px;
  }
  to {
    left: 0;
    width: 100%;
  }
}

@keyframes ele_1 {
  from {
    left: -428px;
  }
  to {
    left: 0;
  }
}

@keyframes ele_2 {
  from {
    right: -632px;
  }
  to {
    right: 0;
  }
}

@keyframes bottom_1 {
  from {
    right: -562px;
  }
  to {
    right: 0;
    width: 100%;
  }
}

@keyframes bottom_2 {
  from {
    left: -918px;
  }
  to {
    left: 0;
  }
}
</style>
